import { Screen } from "../../../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../../../stackflow/routes/types";

type PathParams = {
  /**
   * 로컬프로필 ID
   */
  local_profile_id: string;
};
type QueryParams = {};
export type ActivityLocalProfileUpdateBackgroundImageParams = PathParams &
  QueryParams;

const ActivityLocalProfileUpdateBackgroundImageLayout: ActivityLayoutComponentType<
  ActivityLocalProfileUpdateBackgroundImageParams
> = ({ children }) => {
  return <Screen appBar={{ title: "사진", border: false }}>{children}</Screen>;
};

export default ActivityLocalProfileUpdateBackgroundImageLayout;
